//
//
//  Dashboard Home
//
//

import {Container, Flex, Grid, LoadingOverlay, Paper, Table, Text, Title, rem} from "@mantine/core";
import {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import SummaryBarChart from "../../../../components/SummaryBarChart";
import CategoriesWeightChart from "../../../../components/CategoriesWeightChart";
import { IconCategory, IconCheck, IconLoader } from "@tabler/icons-react";
import { generateColorFromString } from "../../../../utils";
import { CategoryColor, SummariesByCategory, StatisticsResponse } from "../../../../interfaces";
import { useAuth0 } from "@auth0/auth0-react";
import Api from "../../../../api";


function DashboardOverview() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false)
    const { getAccessTokenSilently } = useAuth0()
    const [completeSummaries, setCompleteSummaries] = useState<number | undefined>(undefined)
    const [pendingSummaries, setPendingSummaries] = useState<number | undefined>(undefined)
    const [totalCategories, setTotalCategories] = useState(0)
    const [summariesByDate, setSummariesByDate] = useState<any>([])
    const [summariesByCategory, setSummariesByCategory] = useState<SummariesByCategory[]>([])
    const [categoriesByColor, setCategoriesByColor] = useState<CategoryColor[]>([])
    const [firstColHeight, setFirstColHeight] = useState('auto');
    const secondColRef = useRef(null);

    
    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    useEffect(() => {
        retrieveSummaryCount()
    }
    , [])

    useEffect(() => {
        const updateHeight = () => {
            if (secondColRef.current) {
                setFirstColHeight(`${(secondColRef.current as HTMLElement).offsetHeight}px`);
            }
        };

        updateHeight();
        const resizeObserver = new ResizeObserver(updateHeight);
        if (secondColRef.current) {
            resizeObserver.observe(secondColRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [secondColRef]);

    async function retrieveSummaryCount() {
        try {
          setLoading(true)
          const response = await Api.getStatistics(await getAccessToken());
          const input: StatisticsResponse['summaries_by_date'] = response["summaries_by_date"];
          const cat_By_Color: CategoryColor[] = [];
          const summariesByCategoryData: SummariesByCategory[] = [];
      
          const data = input.map(item => {
            const monthData: Record<string, number> = { month: item.month };
      
            for (const [key, value] of Object.entries<number>(item.categories)) {
              const name = key || 'none'; 
              const color = generateColorFromString(name);
      
              if (!cat_By_Color.some(category => category.name === name)) {
                cat_By_Color.push({ name, color });
              }
      
              monthData[name] = value;
      
              const existingCategoryIndex = summariesByCategoryData.findIndex(category => category.name === name);
              if (existingCategoryIndex === -1) {
                summariesByCategoryData.push({ name, value, color });
              } else {
                summariesByCategoryData[existingCategoryIndex].value += value;
              }
            }
            return monthData;
          });
      
          setCategoriesByColor(cat_By_Color);
          setSummariesByDate(data);
          setSummariesByCategory(summariesByCategoryData);
      
          // Set other summary counts
          setCompleteSummaries(response["complete_summaries"]);
          setPendingSummaries(response["pending_summaries"]);
          setTotalCategories(response["total_categories"]);
          setLoading(false)
        } catch (error) {
          console.error('Error retrieving summary count:', error);
          // Handle error appropriately
        }
      }
      
      

    const rows = summariesByCategory.map((element) => (
        <>
        <Table.Tr key={element.name}>
          <Table.Td>{element.name}</Table.Td>
          <Table.Td>{element.value}</Table.Td>
        </Table.Tr>
      </>
      ));


    return (
        <div>
            <Container mt={40}>
                <LoadingOverlay visible={loading}/>
            </Container>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Overview")}</Title>
                </Flex>
                <Grid grow gutter='xl'>
                    <Grid.Col span={4}>
                        <Paper shadow="sm" p="xl" radius={'md'}>
                            <Flex justify={'center'} align={'center'} direction={'column'}>
                                <IconCheck stroke={1.5} color="green" style={{ width: rem(40), height: rem(40) }}/>
                                <Title my={'sm'} size="h1">{completeSummaries ? completeSummaries : '-'}</Title>
                                <Text c='dimmed' size="sm">
                                    Complete summaries
                                </Text>
                            </Flex>
                        </Paper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Paper shadow="sm" p="xl" radius={'md'}>
                            <Flex justify={'center'} align={'center'} direction={'column'}>
                                <IconLoader stroke={1.5} color="pink" style={{ width: rem(40), height: rem(40) }}/>
                                <Title my={'sm'} size="h1">{pendingSummaries ? pendingSummaries : '-'}</Title>
                                <Text c='dimmed' size="sm">
                                 In progress summaries
                                </Text>
                            </Flex>
                        </Paper>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Paper shadow="sm" p="xl" radius={'md'}>
                        <Flex justify={'center'} align={'center'} direction={'column'}>
                                <IconCategory stroke={1.5} color="orange" style={{ width: rem(40), height: rem(40) }}/>
                                <Title my={'sm'} size="h1">{totalCategories ? totalCategories : '-'}</Title>
                                <Text c='dimmed' size="sm">
                                    Total categories
                                </Text>
                            </Flex>
                        </Paper>
                   </Grid.Col>
                    <Grid.Col span={8}>
                        <Paper shadow="sm" p="xl" radius={'md'} style={{ height: firstColHeight }}>
                            <Text c='dimmed' size="sm">
                                    Summaries by date
                            </Text>
                            <Flex justify={'center'} align={'center'} direction={'column'} h={'100%'}>
                                <SummaryBarChart
                                    data={summariesByDate}
                                    categoriesByColor={categoriesByColor}
                                />
                            </Flex>
                        </Paper>
                        </Grid.Col>
                    <Grid.Col span={4}>
                        <Paper shadow="sm" p="xl" radius={'md'} ref={secondColRef}>
                            <Text c='dimmed' size="sm">
                                Category distribution
                            </Text>
                            <Flex justify={'center'} align={'center'} direction={'column'}>
                                <CategoriesWeightChart data={summariesByCategory}/>
                                <Table>
                                <Table.Thead>
                                    <Table.Tr>
                                    <Table.Th>Name</Table.Th>
                                    <Table.Th>Summaries</Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>{rows}</Table.Tbody>
                                </Table>
                            </Flex>
                        </Paper>
                    </Grid.Col>
                </Grid>
            </Container>
        </div>
    )
}

export default DashboardOverview