import {
    Button,
    Flex,
    rem,
    Title,
    Container
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { IconCirclePlus } from "@tabler/icons-react";
import { Socket } from "socket.io-client";
//import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import Api from "../../api.ts";
import { onResourceUpdate, sortByUpdatedAt } from "../../utils.ts";
import { Response } from "../../interfaces.ts";
import { useApiErrorHandler } from "../../hooks.ts";
import { useSocket } from "../../contexts/SocketContext.tsx";

//import ResponseDetail from "./Detail.tsx";
import ResponsesDataTable from "./DataTable.tsx";
import PaginationControl from "../../components/PaginationControl.tsx";
import ResponseCreateModal from "./Create.tsx"


function ResponseList() {
    const { t } = useTranslation();
    const pageLength = 10

    const { socket }: { socket: Socket } = useSocket()
    const handleError = useApiErrorHandler()
    const { user, getAccessTokenSilently } = useAuth0()
    const [loading, setLoading] = useState(true)
    //const [deleteLoading, setDeleteLoading] = useState(false)
    //const [updateLoading, setUpdateLoading] = useState(false)
    const [selectedResponse, setSelectedResponse] = useState<Response | null>()
    const responsesRef = useRef<Response[]>([])
    const [responses, setResponses] = useState<Response[]>([])
    const [createResponseOpen, setCreateResponseOpen] = useState(false)
    //const [createResponseLoading, setCreateResponseLoading] = useState(false)
    const [totalResponses, setTotalResponses] = useState(0)
    const [page, setPage] = useState(1)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function loadResponsesPage() {
        setLoading(true)
        Api.getResponsesPage(await getAccessToken(), page, pageLength)
            .then(pageResponses => {
                if (pageResponses["items"].length < 1 && page > 1) {
                    setTotalResponses(totalResponses-1)
                } else {
                    responsesRef.current = pageResponses["items"]
                    setResponses(sortByUpdatedAt(responsesRef.current))
                    setTotalResponses(pageResponses["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadResponsesPage()
    }, [user?.id, page])

    useEffect(() => {
        function onAdd(added: Response) {
            const newResponse = {...added, _isNew: true}
            responsesRef.current = [newResponse, ...responsesRef.current]
            setResponses(responsesRef.current)
        }

        function onUpdate(updated: Response) {
            if (updated.user_id === user?.id) {
                responsesRef.current = onResourceUpdate(updated, responsesRef.current)
                setResponses(responsesRef.current)
            }
            if (updated.id == selectedResponse?.id) {
                setSelectedResponse({ ...updated })
            }
        }

        function onDelete(deleted: Response) {
            if (deleted.user_id === user?.id) {
                loadResponsesPage()
            }
            if (deleted.id === selectedResponse?.id) {
                setSelectedResponse(null)
            }
        }

        // noinspection DuplicatedCode
        socket.on("response:add", onAdd)
        socket.on("response:update", onUpdate)
        socket.on("response:delete", onDelete)

        return () => {
            socket.off("response:add", onAdd)
            socket.off("response:update", onUpdate)
            socket.off("response:delete", onDelete)
        }
    }, [user?.id, socket, page])
    
    /*
    await function onUpdateResponse(response: Response, newValues: any) {
        setUpdateLoading(true)
        Api.updateResponse(await getAccessToken(), response.id, newValues)
            .then(updatedResponse => {
                const newResponses = responses.map((res: Response) => {
                    if (response.id === res.id) {
                        return updatedResponse
                    } else {
                        return res
                    }
                })
                setResponses(sortByUpdatedAt(newResponses))
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    await function onDeleteResponse(response: Response) {
        setDeleteLoading(true)
        Api.deleteResponse(await getAccessToken(), response.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedResponse(null)
            }).catch(err => {
                if (err.response.status == 409) {
                    // Response used in story
                    err.response.json()
                        .then(() => {
                            notifications.show({
                                title: t("Error"),
                                message: t("Error deleting the response"),
                                color: "red"
                            })
                            setDeleteLoading(false)
                        })
                } else {
                    console.error(err);
                    handleError(err)
                    setDeleteLoading(false)
                }
            })
    }
    */

    return (
        <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Responses")}</Title>

                    <Button
                        onClick={() => setCreateResponseOpen(true)}
                        leftSection={<IconCirclePlus size={16} />}
                    >
                        {t("Create response")}
                    </Button>
                </Flex>


                <ResponsesDataTable
                    responses={responses}
                    loading={loading}
                    onCreate={() => setCreateResponseOpen(true)}
                />
                <PaginationControl
                    totalElements={totalResponses}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>

            <ResponseCreateModal 
                opened={createResponseOpen}
                setOpened={setCreateResponseOpen}
            />
        </>
    )

}

export default ResponseList