//
//
//  Project
//
//

import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Api from "../../api.ts";
import { useTranslation } from "react-i18next";
import {Anchor, Badge, Box, Button, Center, Container, Flex, LoadingOverlay, Paper, Skeleton, Spoiler, Tabs, Text, Title, rem} from "@mantine/core";
import { Response } from "../../interfaces.ts";
import {Socket} from "socket.io-client";
import { useSocket } from "../../contexts/SocketContext.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import classes from "./Detail.module.css"
import {modals} from "@mantine/modals";
import { formatDate, generateColorFromString} from "../../utils.ts";
import { IconArrowLeft, IconFile, IconTrash, IconUserQuestion } from "@tabler/icons-react";
import FormattedText from "../../components/FormattedText.tsx";
import AnonymizedText from "../../components/StyledAnonymizedText.tsx";


function ResponseDetail() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { getAccessTokenSilently } = useAuth0()
    const [deleteLoading, setDeleteLoading] = useState(false)
    const {socket}: {socket: Socket} = useSocket()
    const { responseId } = useParams()
    const [response, setResponse] = useState<Response | null>(null)

    const colorByStatus: Record<string, string> = {
        "PENDING": "gray",
        "GENERATING": "grape",
        "DONE": "teal",
        "ERROR": "red"
    }
    
    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function loadResponse() {
        if (responseId != null) {
            Api.getResponse(await getAccessToken(), responseId)
                .then(data => {
                    setResponse(data)
                }).catch((err) => {
                    console.error(err);
                })
        }
    }

    useEffect(() => {
        loadResponse()
    }, [responseId, socket])
    

    async function deleteResponse(response: Response) {
        setDeleteLoading(true)
        Api.deleteResponse(await getAccessToken(), response.id)
            .then(() => {
                navigate("/responses")
            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                setDeleteLoading(false)
            })
    }

    function openDeleteModal(response: Response) {
        modals.openConfirmModal({
            title: t('Delete response'),
            centered: true,
            children: (
                <Text size="sm">
                    {t("Are you sure you want to delete this response?")}
                </Text>
            ),
            labels: { confirm: t('Delete response'), cancel: t("No, don't delete it") },
            confirmProps: { color: 'red' , loading: deleteLoading},
            onConfirm: () => deleteResponse(response)
        })
    }

    useEffect(() => {
        function onResponseUpdate(updatedResponse: Response) {
            if (responseId != null && updatedResponse.id === parseInt(responseId)) {
                setResponse(updatedResponse)
            }
        }

        function onResponseDelete(deletedResponse: Response) {
            if (responseId != null && deletedResponse.id === parseInt(responseId)) {
                navigate("/responses")
            }
        }

        socket.on("response:update", onResponseUpdate)
        socket.on("response:delete", onResponseDelete)

        return () => {
            socket.off("response:update", onResponseUpdate)
            socket.off("response:delete", onResponseDelete)
        }
    }, [socket, responseId, navigate])

    if (response == null) {
        return (
            <LoadingOverlay visible loaderProps={{ size: "xl", variant: "oval"}}/>
        )
    } else {
        return (
            <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                >
                    <Title size="h1">{response.summary.name}</Title>
                    <Button
                        onClick={() => openDeleteModal(response)}
                        color="red"
                        leftSection={<IconTrash size={16} />}
                    >
                        {t("Delete")}
                    </Button>
                </Flex>
                <Flex
                    justify="start"
                    align="center"
                    gap={10}
                    direction="row"
                    mb={'sm'}
                >
                    <Anchor c="dimmed" size="sm" mt="sm" mb={rem(20)} component={Link} to="/responses">
                                <Center inline>
                                    <IconArrowLeft style={{width: rem(12), height: rem(12)}} stroke={1.5}/>
                                    <Box ml={5}>{t("Back to responses")}</Box>
                                </Center>
                    </Anchor>
                </Flex>

                <Flex
                    justify="start"
                    align="center"
                    gap={10}
                    direction="row"
                    mb={'sm'}
                >
                    <Text c="dimmed">Status:</Text>
                    <Badge color={colorByStatus[response.status]}>
                            <span style={{textTransform: "capitalize"}}>{response.status}</span>
                    </Badge>
                </Flex>

                <Flex
                    justify="start"
                    align="center"
                    gap={10}
                    direction="row"
                    mb={'xs'}
                >
                    <Text c="dimmed">Created at: </Text>
                    <Text fw={500}>
                        {formatDate(new Date(response.created_at))}
                    </Text>
                </Flex>
                <Flex
                    justify="start"
                    align="center"
                    gap={10}
                    direction="row"
                    mb={'xs'}
                >
                    <Text c="dimmed">Updated at: </Text>
                    <Text fw={500}>
                    {formatDate(new Date(response.updated_at))}
                    </Text>
                </Flex>
                <Flex
                    justify="start"
                    align="center"
                    gap={10}
                    direction="row"
                    mb={rem(30)}
                >
                    <Text c="dimmed">Category:</Text>
                    {response.summary.category?.name ? (
                        <Badge color={generateColorFromString(response.summary.category?.name)}>{response.summary.category?.name}</Badge>
                    ) : (
                        <Text fs="italic">None</Text>
                    )}
                </Flex>
                <Paper shadow="xs" p="xl" mb="md">
                <Title size="h2" mb={rem(50)}>Response</Title>
                <Spoiler className={classes.spoiler} maxHeight={120} showLabel="Show more" hideLabel="Hide" mb={rem(30)}>
                    {response.status === "DONE" ? (
                    <>
                        <FormattedText>
                            {response.text}
                        </FormattedText>
                    </>
                    ) : (
                        <>  
                            <Skeleton height={12} radius="xl" />
                            <Skeleton height={12} mt={6} radius="xl" />
                            <Skeleton height={12} mt={6} width="70%" radius="xl" />
                        </>
                    )
                    }
                </Spoiler>
                </Paper>
                <Paper shadow="xs" p="xl" my="md">
                    <Title size="h2" mb={rem(30)}>Source</Title>
                    <Tabs defaultValue="source">
                    <Tabs.List mb={rem(30)}>
                        <Tabs.Tab value="source" leftSection={ <IconFile />}>
                            Source
                        </Tabs.Tab>
                        <Tabs.Tab value="anonymized-source" leftSection={<IconUserQuestion />}>
                            Anonymized source
                        </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="source">
                        <Spoiler className={classes.spoiler} maxHeight={120} showLabel="Show more" hideLabel="Hide" mb={rem(30)}>
                            <FormattedText>
                                {response.summary.text}
                            </FormattedText>
                        </Spoiler>
                    </Tabs.Panel>
                    <Tabs.Panel value="anonymized-source">
                    <Spoiler className={classes.spoiler} maxHeight={120} showLabel="Show more" hideLabel="Hide" mb={rem(30)}>
                    {response.summary.text_anonymized && (
                           <AnonymizedText text={response.summary.text_anonymized}/>
                        )}
                    </Spoiler>
                    </Tabs.Panel>
                    </Tabs>
                </Paper>
            </Container>
        </>
        )
    }
}

export default ResponseDetail
