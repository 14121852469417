import { Text, Badge } from "@mantine/core";


const tagColorIndex: Record<string, string> = {
    "<LOCATION>": "#F9C80E",
    "<PERSON>": "#43BCCD",
    "<ID>": "#008148",
    "<MISC>": '#F0544F',
    "<ORGANIZATION>": "#BFD7EA",
    "<PHONE_NUMBER>": "#FAD8D6"
    

}

function AnonymizedText({ text }: { text: string }) {


    const processText = () => {
        const regex = /<[^>]+>|[^<>]+/g;
        const elements: JSX.Element[] = [];
        let match: RegExpExecArray | null;

        while ((match = regex.exec(text)) !== null) {
            const isTag = match[0].startsWith('<') && match[0].endsWith('>');

            const lines = match[0].split('\n');
            lines.forEach((line, index) => {
                elements.push(
                    <Text key={`${match!.index}-${index}`} component="span">
                        {isTag ? <Badge  radius="xs" color={tagColorIndex[match![0]]}>{line}</Badge> : line}
                    </Text>
                );
                if (index !== lines.length - 1) {
                    elements.push(<br key={`${match!.index}-${index}-br`} />);
                }
            });
        }

        return elements;
    };

    return <>{processText()}</>;
}

export default AnonymizedText;