import { Button, Container, Flex, NumberInput, Select, Grid, Title, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import Api from "../../api";

const lengthUnits = [
    {"value": "CHARACTERS", "label": "characters"},
    {"value": "WORDS", "label": "words"},
    {"value": "SENTENCES", "label": "sentences"},
    {"value": "PARAGRAPHS", "label": "paragraphs"}
]

function SettingsEdit() {
    const { t } = useTranslation()
    const { getAccessTokenSilently } = useAuth0()
    const [updateLoading, setUpdateLoading] = useState(false)

    const form = useForm({
        initialValues: {
            length: 2,
            unit: lengthUnits[0]["value"],
        }
    })

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function loadSettings() {
        setUpdateLoading(true)
        Api.getSettings(await getAccessToken())
            .then(data => {
                form.setValues({
                    length: data.length,
                    unit: data.unit
                })
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                setUpdateLoading(false)
            })
    }

    useEffect(() => {
        loadSettings()
    }, [])

    async function onSubmit(values: any) {
        setUpdateLoading(true)
        Api.updateSettings(await getAccessToken(), values)
            .then(() => {
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                setUpdateLoading(false)
            })
    }

    return (
        <Container>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Settings")}</Title>
            </Flex>

            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid align="flex-end">
                    <Grid.Col span={2}>
                        <NumberInput
                            label={t("Summary length")}
                            required
                            {...form.getInputProps("length")}
                        />
                    </Grid.Col>

                    <Grid.Col span={4}>
                        <Select
                            data={lengthUnits}
                            allowDeselect={false}
                            {...form.getInputProps("unit")}
                        />
                    </Grid.Col>
                </Grid>

                <Button type="submit" mt="xl" loading={updateLoading}>
                    {t("Save")}
                </Button>
            </form>
        </Container>
    )

}

export default SettingsEdit
