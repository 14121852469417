import {
    Button,
    Flex,
    rem,
    Title,
    Container
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { IconCirclePlus } from "@tabler/icons-react";
import { Socket } from "socket.io-client";
//import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import Api from "../../api.ts";
import { onResourceUpdate, sortByUpdatedAt } from "../../utils.ts";
import { Summary } from "../../interfaces.ts";
import { useApiErrorHandler } from "../../hooks.ts";
import { useSocket } from "../../contexts/SocketContext.tsx";

//import SummaryDetail from "./Detail.tsx";
import SummariesDataTable from "./DataTable.tsx";
import PaginationControl from "../../components/PaginationControl.tsx";
import SummaryCreateModal from "./Create.tsx";


function SummaryList() {
    const { t } = useTranslation();
    const pageLength = 10

    const { socket }: { socket: Socket } = useSocket()
    const handleError = useApiErrorHandler()
    const { user, getAccessTokenSilently } = useAuth0()
    const [loading, setLoading] = useState(true)
    //const [deleteLoading, setDeleteLoading] = useState(false)
    //const [updateLoading, setUpdateLoading] = useState(false)
    const [selectedSummary, setSelectedSummary] = useState<Summary | null>()
    const summariesRef = useRef<Summary[]>([])
    const [summaries, setSummaries] = useState<Summary[]>([])
    const [createSummaryOpen, setCreateSummaryOpen] = useState(false)
    //const [createSummaryLoading, setCreateSummaryLoading] = useState(false)
    const [totalSummaries, setTotalSummaries] = useState(0)
    const [page, setPage] = useState(1)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function loadSummariesPage() {
        setLoading(true)
        Api.getSummariesPage(await getAccessToken(), page, pageLength)
            .then(pageSummaries => {
                if (pageSummaries["items"].length < 1 && page > 1) {
                    setTotalSummaries(totalSummaries-1)
                } else {
                    summariesRef.current = pageSummaries["items"]
                    setSummaries(sortByUpdatedAt(summariesRef.current))
                    setTotalSummaries(pageSummaries["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadSummariesPage()
    }, [user?.id, page])

    useEffect(() => {
        function onAdd(added: Summary) {
            const newSummary = {...added, _isNew: true}
            summariesRef.current = [newSummary, ...summariesRef.current]
            setSummaries(summariesRef.current)
        }

        function onUpdate(updated: Summary) {
            if (updated.user_id === user?.id) {
                summariesRef.current = onResourceUpdate(updated, summariesRef.current)
                setSummaries(summariesRef.current)
            }
            if (updated.id == selectedSummary?.id) {
                setSelectedSummary({ ...updated })
            }
        }

        function onDelete(deleted: Summary) {
            if (deleted.user_id === user?.id) {
                loadSummariesPage()
            }
            if (deleted.id === selectedSummary?.id) {
                setSelectedSummary(null)
            }
        }

        // noinspection DuplicatedCode
        socket.on("summary:add", onAdd)
        socket.on("summary:update", onUpdate)
        socket.on("summary:delete", onDelete)

        return () => {
            socket.off("summary:add", onAdd)
            socket.off("summary:update", onUpdate)
            socket.off("summary:delete", onDelete)
        }
    }, [user?.id, socket, page])
    
    /*
    await function onUpdateSummary(summary: Summary, newValues: any) {
        setUpdateLoading(true)
        Api.updateSummary(await getAccessToken(), summary.id, newValues)
            .then(updatedSummary => {
                const newSummaries = summaries.map((res: Summary) => {
                    if (summary.id === res.id) {
                        return updatedSummary
                    } else {
                        return res
                    }
                })
                setSummaries(sortByUpdatedAt(newSummaries))
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    await function onDeleteSummary(summary: Summary) {
        setDeleteLoading(true)
        Api.deleteSummary(await getAccessToken(), summary.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedSummary(null)
            }).catch(err => {
                if (err.response.status == 409) {
                    // Summary used in story
                    err.response.json()
                        .then(() => {
                            notifications.show({
                                title: t("Error"),
                                message: t("Error deleting the summary"),
                                color: "red"
                            })
                            setDeleteLoading(false)
                        })
                } else {
                    console.error(err);
                    handleError(err)
                    setDeleteLoading(false)
                }
            })
    }
    */

    return (
        <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Summaries")}</Title>

                    <Button
                        onClick={() => setCreateSummaryOpen(true)}
                        leftSection={<IconCirclePlus size={16} />}
                    >
                        {t("Create summary")}
                    </Button>
                </Flex>


                <SummariesDataTable
                    summaries={summaries}
                    loading={loading}
                    onCreate={() => setCreateSummaryOpen(true)}
                />
                <PaginationControl
                    totalElements={totalSummaries}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>

            <SummaryCreateModal 
                opened={createSummaryOpen}
                setOpened={setCreateSummaryOpen}
            />
        </>
    )

}

export default SummaryList