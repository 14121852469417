
import { raiseForStatus, toJsonOrRaise } from "./utils.ts";
import { Settings, Category, Summary, Response as AIResponse } from "./interfaces.ts";

export interface LoginResponse {
    access_token: string
    token_type: string
}

export class AuthenticationError extends Error {}

export class RequestError extends Error {

    response: Response

    constructor(response: Response) {
        super()
        this.response = response
    }
}


class Api {

    static async getSettings(accessToken: string) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/settings`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }

    static async updateSettings(accessToken: string, values: Record<string, any>): Promise<Settings> {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/settings`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }

    static async getCategoriesPage(accessToken: string, page: number=1, size: number=10) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/category?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }


    static async createCategory(accessToken: string,name: string, description?: string, template?: string) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/category`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                description,
                template
            })
        })
        return toJsonOrRaise(response)
    }


    static async updateCategory(accessToken: string, categoryId: number, values: Record<string, any>): Promise<Category> {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/category/${categoryId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }


    static async deleteCategory(accessToken: string, categoryId: NonNullable<unknown>) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/category/${categoryId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

    static async getSummary(accessToken: string, summaryId: NonNullable<unknown>): Promise<Summary> {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/summary/${summaryId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }


    static async getSummariesPage(accessToken: string, page: number=1, size: number=10, status: string | null=null) {
        console.assert(accessToken != null)

        let params: { page: string; size: string; status?: string } = { page: page.toString(), size: size.toString() }

        if (status !== null) {
            params.status = status
        }

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/summary?` + new URLSearchParams(params), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }


    static async getStatistics(accessToken: string) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/statistics`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }


    static async createSummaryText(accessToken: string, name: string, text: string) {
        console.assert(accessToken != null)

        const formData = new FormData()
        formData.append("name", name)
        formData.append("type", 'TEXT')
        formData.append("text", text)
        const response = await fetch(import.meta.env.VITE_API_URL + `/api/summary`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },

            body: formData
        })
        return toJsonOrRaise(response)
    }


    static async createSummaryAudio(accessToken: string, name: string, file: File, lang: string) {
        console.assert(accessToken != null)

        const formData = new FormData()
        formData.append("name", name)
        formData.append("type", 'AUDIO')
        formData.append("file", file)
        formData.append("lang", lang)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/summary`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
            body: formData
        })
        return toJsonOrRaise(response)
    }

    static async createSummaryFile(accessToken: string, name: string, file: File, selectedEmails: string ) {
        console.assert(accessToken != null)

        const formData = new FormData()
        formData.append("name", name)
        formData.append("type", 'TEXT')
        formData.append("file", file)
        formData.append("selected_emails", selectedEmails)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/summary`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
            },
            body: formData
        })
        return toJsonOrRaise(response)
    }

    static async updateSummary(accessToken: string, summaryId: number, values: Record<string, any>): Promise<Summary> {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/summary/${summaryId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }


    static async deleteSummary(accessToken: string, summaryId: NonNullable<unknown>) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/summary/${summaryId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }


    static async getResponse(accessToken: string, responseId: NonNullable<unknown>): Promise<AIResponse> {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/response/${responseId}`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }


    static async getResponsesPage(accessToken: string, page: number=1, size: number=10) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/response?` + new URLSearchParams({page: page.toString(), size: size.toString()}), {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return toJsonOrRaise(response)
    }


    static async createResponse(accessToken: string, summary_id: number) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/response`, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                summary_id
            })
        })
        return toJsonOrRaise(response)
    }


    static async updateResponse(accessToken: string, responseId: number, values: Record<string, any>): Promise<AIResponse> {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/response/${responseId}`, {
            method: "PATCH",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
        return toJsonOrRaise(response)
    }


    static async deleteResponse(accessToken: string, responseId: NonNullable<unknown>) {
        console.assert(accessToken != null)

        const response = await fetch(import.meta.env.VITE_API_URL + `/api/response/${responseId}`, {
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return raiseForStatus(response)
    }

}

export default Api