//
//
//  Owner Home
//
//

import {AppShell, Button, useMantineColorScheme, Flex, Divider, Image} from "@mantine/core"

import classes from "./index.module.css"
import {useEffect} from "react";
import {
    IconChartLine,
    IconCategory,
    IconFileTextAi,
    IconMessage
} from "@tabler/icons-react";
import {Link, Outlet} from "react-router-dom";
import maiaLogoImg from '../../../assets/maia-summary.svg'
import ProfileMenu from "../../../components/ProfileMenu";


function Dashboard() {
    const currentPath = window.location.pathname
    const activePath = currentPath.substring("/".length)

    const {setColorScheme} = useMantineColorScheme()

    useEffect(() => {
        setColorScheme("light")
    }, [setColorScheme])

    return (
        <AppShell
            navbar={{ width: 260, breakpoint: 'sm', collapsed: { mobile: true } }}
        >
            <AppShell.Navbar className={classes.navbar} p="md">
                <Flex gap={10} justify="center" align="center" mb={16}>
                    <Image src={maiaLogoImg} alt="Maia Logo" width={60} height={60} />
                </Flex>
                <Flex direction="column" gap={8} className={classes.navbarContent}>
                    <Button
                        variant={activePath.includes("dashboard") ? "gradient" : "subtle"}
                        leftSection={<IconChartLine size={20}/>}
                        component={Link}
                        fullWidth
                        justify="left"
                        to="/dashboard"
                    >
                        Overview
                    </Button>

                    <Button
                       variant={activePath.includes("categories") ? "gradient" : "subtle"}
                        leftSection={<IconCategory size={20}/>}
                        component={Link}
                        fullWidth
                        justify="left"
                        to="/categories"
                    >
                        Categories
                    </Button>

                    <Button
                        leftSection={<IconFileTextAi size={20}/>}
                        variant={activePath.includes("summaries") ? "gradient" : "subtle"}
                        justify="left"
                        component={Link}
                        fullWidth
                        to="/summaries"
                    >
                        Summaries
                    </Button>

                    <Button
                        leftSection={<IconMessage size={20}/>}
                        variant={activePath.includes("responses") ? "gradient" : "subtle"}
                        justify="left"
                        component={Link}
                        fullWidth
                        to="/responses"
                    >
                        Responses
                    </Button>
                </Flex>
                <Flex direction="column">
                    <Divider mb={10}/>
                    <ProfileMenu
                        includeDashboard={false}
                    />
                </Flex>
            </AppShell.Navbar>
            <AppShell.Main>
                <Outlet/>
            </AppShell.Main>
        </AppShell>
    )
}

export default Dashboard