//
//
//  Require Logged In
//
//

import {Outlet} from "react-router-dom"
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {SocketProvider} from "../contexts/SocketContext.tsx";


function RequireLoggedIn() {
    const Component = withAuthenticationRequired(Outlet, {
        returnTo: () => window.location.hash.substring(1),
    })
    return (
        <>
            <SocketProvider>
                <Component/>
            </SocketProvider>
        </>
    )
}

export default RequireLoggedIn