import {
    Button,
    Divider,
    Drawer,
    Text,
    TextInput,
    Textarea
} from "@mantine/core"

import {
    IconTrash
} from "@tabler/icons-react";


import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { Category } from "../../interfaces";
import classes from "./CategoryDrawer.module.css"


interface CategoryForm {
    name: string
    description?: string
}


function CategoryDrawer({category, opened, onClose, onUpdate, updateLoading, onDelete, deleteLoading}: {
    category: Category,
    opened: boolean,
    onClose: () => void,
    onUpdate: (category: Category, values: CategoryForm) => void,
    updateLoading: boolean,
    onDelete: (category: Category) => void,
    deleteLoading: boolean,
}) {

    const { t } = useTranslation()

    const updateCategoryForm = useForm({
        initialValues: {
            name: category.name,
            description: category.description,
            template: category.template
        }
    })

    function onSubmitUpdateCategory(values: CategoryForm) {
        onUpdate(category, values)
    }

    function openDeleteModal(category: Category) {
        modals.openConfirmModal({
            title: t('Delete summary'),
            centered: true,
            children: (
                <Text size="sm">
                    {t("Are you sure you want to delete this category?")}
                </Text>
            ),
            labels: { confirm: t('Delete category'), cancel: t("No, don't delete it") },
            confirmProps: { color: 'red' , loading: deleteLoading},
            onConfirm: () => onDelete(category)
        })
    }

    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            title={category.name}
            position="right"
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <form onSubmit={updateCategoryForm.onSubmit(onSubmitUpdateCategory)}>
                <TextInput
                  withAsterisk
                  required
                  label={t("Name")}
                  placeholder={t("Super duper name")}
                  {...updateCategoryForm.getInputProps("name")}
                />
                <Textarea
                  label={t("Description")}
                  autosize
                  minRows={4}
                  placeholder={t("Super duper description")}
                  {...updateCategoryForm.getInputProps("description")}
                />
                <Textarea
                  label={t("Template")}
                  minRows={8}
                  autosize
                  placeholder={t("Super duper template")}
                  {...updateCategoryForm.getInputProps("template")}
                />
                <Button mt="md" type="submit" loading={updateLoading}>{t("Save")}</Button>
            </form>
            <div className={classes.footer}>
                <Divider mt="xl" mb="xl"/>
                <Button loading={deleteLoading} color="red" leftSection={<IconTrash size={16}/>} fullWidth
                        onClick={() => openDeleteModal(category)}>
                    {t("Delete category")}
                </Button>
            </div>
        </Drawer>
    )

}

export default CategoryDrawer