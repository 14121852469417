//
//
//  Project
//
//

import { Link, useNavigate, useParams } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import Api from "../../api.ts";
import { useTranslation } from "react-i18next";
import { Anchor, Badge, Box, Button, Center, Container, Flex, Loader, LoadingOverlay, Paper, Skeleton, Spoiler, Tabs, Text, Title, rem, useMantineTheme } from "@mantine/core";
import { Summary, Transcript } from "../../interfaces.ts";
import { Socket } from "socket.io-client";
import { useSocket } from "../../contexts/SocketContext.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import classes from "./Detail.module.css"
import { modals } from "@mantine/modals";
import { formatDate, generateColorFromString } from "../../utils.ts";
import { IconArrowLeft, IconFile, IconMoodCheck, IconMoodHappy, IconMoodNeutral, IconMoodSad, IconTrash, IconUserQuestion, IconVolume } from "@tabler/icons-react";
import FormattedText from "../../components/FormattedText.tsx";
import AnonymizedText from "../../components/StyledAnonymizedText.tsx";

const colorByStatus: Record<string, string> = {
    "PENDING": "gray",
    "TRANSCRIBING": "indigo",
    "SUMMARIZING": "grape",
    "DONE": "teal",
    "ERROR": "red"
}

const iconByType: Record<string, React.ReactNode> = {
    "AUDIO": <IconVolume style={{ width: rem(12), height: rem(12) }} />,
    "TEXT": <IconFile style={{ width: rem(12), height: rem(12) }} />,
}


const AudioPlayer = memo(function AudioPlayer({ audioUrl }: { audioUrl: string }) {
    return (
        <>
            <audio controls src={audioUrl} style={{ width: "100%", pointerEvents: 'all' }} onClick={ev => ev.stopPropagation()}></audio>
        </>
    );
});


function SummaryDetail() {
    const theme = useMantineTheme()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { getAccessTokenSilently } = useAuth0()
    const [deleteLoading, setDeleteLoading] = useState(false)
    const { socket }: { socket: Socket } = useSocket()
    const { summaryId } = useParams()
    const [summary, setSummary] = useState<Summary | null>(null)
    const [audioUrl, setAudioUrl] = useState<string>("")
    const [streamingTranscript, setStreamingTranscript] = useState<string>("")
    const [renderIndex, setRenderIndex] = useState(0);

    const SummaryColor: Record<string, string> = {
        "TEXT": theme.colors.orange[6],
        "AUDIO": theme.colors.pink[6]
    }

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
        })

        return accessToken
    }

    useEffect(() => {
        loadSummary()
    }, [summaryId, socket])

    async function loadSummary() {

        if (summaryId != null) {
            Api.getSummary(await getAccessToken(), summaryId)
                .then(data => {
                    setSummary(data)
                    setAudioUrl(data.audio_url)
                }).catch((err) => {
                    console.error(err);
                })
        }
    }

    async function deleteSummary(summary: Summary) {
        setDeleteLoading(true)
        Api.deleteSummary(await getAccessToken(), summary.id)
            .then(() => {
                navigate("/summaries")
            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                setDeleteLoading(false)
            })
    }

    function openDeleteModal(summary: Summary) {
        modals.openConfirmModal({
            title: t('Delete summary'),
            centered: true,
            children: (
                <Text size="sm">
                    {t("Are you sure you want to delete this summary?")}
                </Text>
            ),
            labels: { confirm: t('Delete summary'), cancel: t("No, don't delete it") },
            confirmProps: { color: 'red', loading: deleteLoading },
            onConfirm: () => deleteSummary(summary)
        })
    }

    useEffect(() => {
        if (renderIndex === 0) {
            const initialIndex = Math.max(streamingTranscript.length - 100, 0);
            setRenderIndex(initialIndex);
        }
        if (renderIndex < streamingTranscript.length) {
            const timer = setTimeout(() => {
                setRenderIndex(renderIndex + 1);
            }, 20);

            return () => clearTimeout(timer);
        }
    }, [streamingTranscript, renderIndex]);


    useEffect(() => {
        function onTranscriptUpdate(transcript: Transcript) {
            if (transcript.summary_id == summaryId) {
                setStreamingTranscript(transcript.text)
            }
        }
        function onSummaryUpdate(updatedSummary: Summary) {
            if (summaryId != null && updatedSummary.id === parseInt(summaryId)) {
                setSummary(updatedSummary)
            }
        }

        function onSummaryDelete(deletedSummary: Summary) {
            if (summaryId != null && deletedSummary.id === parseInt(summaryId)) {
                navigate("/summaries")
            }
        }

        socket.on("summary:update", onSummaryUpdate)
        socket.on("summary:delete", onSummaryDelete)
        socket.on("transcript:update", onTranscriptUpdate)


        return () => {
            socket.off("summary:update", onSummaryUpdate)
            socket.off("summary:delete", onSummaryDelete)
        }
    }, [socket, summaryId, navigate])

    if (summary == null) {
        return (
            <LoadingOverlay visible loaderProps={{ size: "xl", variant: "oval" }} />
        )
    } else {
        return (
            <>
                <Container>
                    <Flex
                        justify="space-between"
                        align="center"
                        direction="row"
                        mt={rem(50)}
                    >
                        <Title size="h1">{summary.name}</Title>
                        <Button
                            onClick={() => openDeleteModal(summary)}
                            color="red"
                            leftSection={<IconTrash size={16} />}
                        >
                            {t("Delete")}
                        </Button>
                    </Flex>
                    <Flex
                        justify="start"
                        align="center"
                        gap={10}
                        direction="row"
                        mb={'sm'}
                    >
                        <Anchor c="dimmed" size="sm" mt="sm" mb={rem(20)} component={Link} to="/summaries">
                            <Center inline>
                                <IconArrowLeft style={{ width: rem(12), height: rem(12) }} stroke={1.5} />
                                <Box ml={5}>{t("Back to summaries")}</Box>
                            </Center>
                        </Anchor>
                    </Flex>
                    <Flex
                        justify="start"
                        align="center"
                        gap={10}
                        direction="row"
                        mb={'sm'}
                    >
                        <Text c="dimmed">Status:</Text>
                        <Badge color={colorByStatus[summary.status]}>
                            <span style={{ textTransform: "capitalize" }}>{summary.status}</span>
                        </Badge>
                    </Flex>
                    <Flex
                        justify="start"
                        align="center"
                        gap={10}
                        direction="row"
                        mb={'xs'}
                    >
                        <Text c="dimmed">Type:</Text>
                        <Badge variant="filled" color={SummaryColor[summary.type]} leftSection={iconByType[summary.type]}>
                            <span style={{ textTransform: "capitalize" }}>{summary.type}</span>
                        </Badge>
                    </Flex>
                    <Flex
                        justify="start"
                        align="center"
                        gap={10}
                        direction="row"
                        mb={'xs'}
                    >
                        <Text c="dimmed">Created at: </Text>
                        <Text fw={500}>
                            {formatDate(new Date(summary.created_at))}
                        </Text>
                    </Flex>
                    <Flex
                        justify="start"
                        align="center"
                        gap={10}
                        direction="row"
                        mb={'xs'}
                    >
                        <Text c="dimmed">Updated at: </Text>
                        <Text fw={500}>
                            {formatDate(new Date(summary.updated_at))}
                        </Text>
                    </Flex>
                    <Flex
                        justify="start"
                        align="center"
                        gap={10}
                        direction="row"
                        mb={rem(30)}
                    >
                        <Text c="dimmed">Category:</Text>
                        {summary.status === 'DONE' && (
                            <>
                                {summary?.category?.name ? (
                                    <Badge color={generateColorFromString(summary?.category?.name)}>{summary?.category?.name}</Badge>
                                ) : (
                                    <Text fs="italic">None</Text>
                                )}
                            </>
                        )}
                    </Flex>
                    {summary.status === "DONE" && (
                        <>
                            <Paper shadow="xs" p="xl" mb="md">
                                <Title size="h2" mb={rem(50)}>Summary</Title>
                                <Spoiler className={classes.spoiler} maxHeight={120} showLabel="Show more" hideLabel="Hide" mb={rem(30)}>
                                    <FormattedText>
                                        {summary.summary}
                                    </FormattedText>
                                </Spoiler>
                            </Paper>
                        </>)
                    }
                    {summary.type === "AUDIO" && (
                        <>
                            <Paper shadow="xs" p="xl" mb="md">
                                <Title size="h2" mb={rem(50)}>Audio Preview</Title>
                                <Flex direction="column" align="center" justify="center" gap={8}>
                                    <AudioPlayer audioUrl={audioUrl} />
                                </Flex>
                            </Paper>
                        </>)
                    }
                    <Paper shadow="xs" p="xl" my="md">
                        <Title size="h2" mb={rem(30)}>Source</Title>
                        <Tabs defaultValue="source">
                            <Tabs.List mb={rem(30)}>
                                <Tabs.Tab value="source" rightSection={summary.status === 'TRANSCRIBING' ? <Loader color="orange" type="dots" /> : ''} leftSection={<IconFile />}>
                                    Source
                                </Tabs.Tab>
                                <Tabs.Tab value="anonymized-source" disabled={summary.status !== "DONE"} rightSection={summary.status === 'SUMMARIZING' ? <Loader color="orange" type="dots" /> : ''} leftSection={<IconUserQuestion />}>
                                    Anonymized source
                                </Tabs.Tab>
                                {summary.type === "AUDIO" && (
                                <Tabs.Tab value="sentiment-analysis" disabled={summary.sentiment_analysis == null} rightSection={summary.status === 'TRANSCRIBING' ? <Loader color="orange" type="dots" /> : ''} leftSection={<IconMoodCheck />}>
                                    Sentiment Analysis
                                </Tabs.Tab>
                                )}
                            </Tabs.List>
                            <Tabs.Panel value="source">
                                <Spoiler className={classes.spoiler} maxHeight={120} showLabel="Show more" hideLabel="Hide" mb={rem(30)}>
                                    {summary.text !== '' || streamingTranscript !== '' || summary.status === 'ERROR' ? (
                                        <FormattedText>
                                            {summary.text !== '' ? summary.text : streamingTranscript.substring(0, renderIndex)}
                                        </FormattedText>
                                    ) : (
                                        <>
                                            <Skeleton height={12} radius="xl" />
                                            <Skeleton height={12} mt={6} radius="xl" />
                                            <Skeleton height={12} mt={6} width="70%" radius="xl" />
                                        </>
                                    )}
                                </Spoiler>
                            </Tabs.Panel>
                            <Tabs.Panel value="anonymized-source">
                                <Spoiler className={classes.spoiler} maxHeight={120} showLabel="Show more" hideLabel="Hide" mb={rem(30)}>
                                    {summary.text_anonymized && (
                                        <AnonymizedText text={summary.text_anonymized} />
                                    )}
                                </Spoiler>
                            </Tabs.Panel>
                            <Tabs.Panel value="sentiment-analysis">
                                <Flex w={'100%'} justify={'space-between'} align={'center'} gap={10}>
                                    <Paper shadow="sm" p="xl" radius={'md'} flex={1}>
                                        <Flex justify={'center'} align={'center'} direction={'column'}>
                                            <IconMoodHappy stroke={1.5} color="green" style={{ width: rem(40), height: rem(40) }} />
                                            <Title my={'sm'} size="h1">{summary.sentiment_analysis?.percentage_positive} %</Title>
                                            <Text c='dimmed' size="sm">
                                                Positive
                                            </Text>
                                        </Flex>
                                    </Paper>
                                    <Paper shadow="sm" p="xl" radius={'md'} flex={1}>
                                        <Flex justify={'center'} align={'center'} direction={'column'}>
                                            <IconMoodNeutral stroke={1.5} color="orange" style={{ width: rem(40), height: rem(40) }} />
                                            <Title my={'sm'} size="h1">{summary.sentiment_analysis?.percentage_neutral} %</Title>
                                            <Text c='dimmed' size="sm">
                                                Neutral
                                            </Text>
                                        </Flex>
                                    </Paper>
                                    <Paper shadow="sm" p="xl" radius={'md'} flex={1}>
                                        <Flex justify={'center'} align={'center'} direction={'column'}>
                                            <IconMoodSad stroke={1.5} color="red" style={{ width: rem(40), height: rem(40) }} />
                                            <Title my={'sm'} size="h1">{summary.sentiment_analysis?.percentage_negative} %</Title>
                                            <Text c='dimmed' size="sm">
                                                Negative
                                            </Text>
                                        </Flex>
                                    </Paper>
                                </Flex>
                            </Tabs.Panel>
                        </Tabs>
                    </Paper>

                </Container>
            </>
        )
    }
}

export default SummaryDetail
