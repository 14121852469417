import {
    Button,
    Flex,
    Group,
    Modal,
    rem,
    TextInput,
    Title,
    Container,
    Textarea
} from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { IconCirclePlus } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { Socket } from "socket.io-client";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import Api from "../../api.ts";
import { onResourceUpdate, sortByUpdatedAt } from "../../utils.ts";
import { Category } from "../../interfaces.ts";
import { useApiErrorHandler } from "../../hooks.ts";
import { useSocket } from "../../contexts/SocketContext.tsx";

import CategoryDrawer from "./Detail.tsx";
import CategoriesDataTable from "./DataTable.tsx";
import PaginationControl from "../../components/PaginationControl.tsx";


interface CategoryForm {
    name: string,
    description?: string
    template?: string
}

function CategoryList() {
    const { t } = useTranslation();
    const pageLength = 5

    const { socket }: { socket: Socket } = useSocket()
    const handleError = useApiErrorHandler()
    const { user, getAccessTokenSilently } = useAuth0()
    const [loading, setLoading] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState<Category | null>()
    const categoriesRef = useRef<Category[]>([])
    const [categories, setCategories] = useState<Category[]>([])
    const [createCategoryOpen, setCreateCategoryOpen] = useState(false)
    const [createCategoryLoading, setCreateCategoryLoading] = useState(false)
    const [totalCategories, setTotalCategories] = useState(0)
    const [page, setPage] = useState(1)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    async function loadCategoriesPage() {
        setLoading(true)
        Api.getCategoriesPage(await getAccessToken(), page, pageLength)
            .then(pageCategories => {
                if (pageCategories["items"].length < 1 && page > 1) {
                    setTotalCategories(totalCategories-1)
                } else {
                    categoriesRef.current = pageCategories["items"]
                    setCategories(sortByUpdatedAt(categoriesRef.current))
                    setTotalCategories(pageCategories["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadCategoriesPage()
    }, [page])

    useEffect(() => {
        function onAdd(added: Category) {
            const newCategory = {...added, _isNew: true}
            categoriesRef.current = [newCategory, ...categoriesRef.current]
            setCategories(categoriesRef.current)
        }

        function onUpdate(updated: Category) {
            if (updated.user_id === user?.id) {
                categoriesRef.current = onResourceUpdate(updated, categoriesRef.current)
                setCategories(categoriesRef.current)
            }
            if (updated.id == selectedCategory?.id) {
                setSelectedCategory({ ...updated })
            }
        }

        function onDelete(deleted: Category) {
            loadCategoriesPage()

            if (deleted.id === selectedCategory?.id) {
                setSelectedCategory(null)
            }
        }

        // noinspection DuplicatedCode
        socket.on("category:add", onAdd)
        socket.on("category:update", onUpdate)
        socket.on("category:delete", onDelete)

        return () => {
            socket.off("category:add", onAdd)
            socket.off("category:update", onUpdate)
            socket.off("category:delete", onDelete)
        }
    }, [socket, page])

    const createCategoryForm = useForm({
        initialValues: {
            name: "",
            description: "",
            template: ""
        },
        validate: {
            name: value => value.length < 1
        }
    })

    async function onSubmitCreateCategory(values: CategoryForm) {
        setCreateCategoryLoading(true)
        Api.createCategory(await getAccessToken(), values.name, values.description, values.template)
            .then(created => {
                setCreateCategoryLoading(false)
                setCreateCategoryOpen(false)
                createCategoryForm.reset()
                setSelectedCategory(created)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateCategoryLoading(false)
            })
    }

    async function onUpdateCategory(category: Category, newValues: any) {
        setUpdateLoading(true)
        Api.updateCategory(await getAccessToken(), category.id, newValues)
            .then(updatedCategory => {
                const newCategories = categories.map((res: Category) => {
                    if (category.id === res.id) {
                        return updatedCategory
                    } else {
                        return res
                    }
                })
                setCategories(sortByUpdatedAt(newCategories))
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    async function onDeleteCategory(category: Category) {
        setDeleteLoading(true)
        Api.deleteCategory(await getAccessToken(), category.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedCategory(null)
            }).catch(err => {
                if (err.response.status == 409) {
                    // Category used in story
                    err.response.json()
                        .then(() => {
                            notifications.show({
                                title: t("Error"),
                                message: t("Error deleting the category"),
                                color: "red"
                            })
                            setDeleteLoading(false)
                        })
                } else {
                    console.error(err);
                    handleError(err)
                    setDeleteLoading(false)
                }
            })
    }

    return (
        <>
            <Container>
                <Flex
                    justify="space-between"
                    align="center"
                    direction="row"
                    mt={rem(50)}
                    mb={rem(30)}
                >
                    <Title size="h1">{t("Categories")}</Title>

                    <Button
                        onClick={() => setCreateCategoryOpen(true)}
                        leftSection={<IconCirclePlus size={16} />}
                    >
                        {t("Create category")}
                    </Button>
                </Flex>
                <CategoriesDataTable
                    categories={categories}
                    loading={loading}
                    onCreate={() => setCreateCategoryOpen(true)}
                    onSelection={category => setSelectedCategory(category)}
                />
                <PaginationControl
                    totalElements={totalCategories}
                    page={page}
                    pageLength={pageLength}
                    onChange={setPage}
                />
            </Container>
            <Modal opened={createCategoryOpen} onClose={() => setCreateCategoryOpen(false)} title={t("Create category")}>
                <form onSubmit={createCategoryForm.onSubmit(onSubmitCreateCategory)}>
                    <TextInput
                        withAsterisk
                        label={t("Name")}
                        required
                        placeholder={t("Super duper name")}
                        {...createCategoryForm.getInputProps("name")}
                    />
                    <Textarea
                        label={t("Description")}
                        minRows={4}
                        placeholder={t("Super duper description")}
                        autosize
                        {...createCategoryForm.getInputProps("description")}
                    />
                    <Textarea
                        label={t("Template")}
                        placeholder={t("Super duper template")}
                        minRows={8}
                        autosize
                        {...createCategoryForm.getInputProps("template")}
                    />
                    <Group align="right" mt="md">
                        <Button type="submit" loading={createCategoryLoading}>{t("Submit")}</Button>
                    </Group>
                </form>
            </Modal>
            {selectedCategory != null &&
                <CategoryDrawer
                    category={selectedCategory}
                    opened={true}
                    onClose={() => setSelectedCategory(null)}
                    onUpdate={onUpdateCategory}
                    updateLoading={updateLoading}
                    onDelete={onDeleteCategory}
                    deleteLoading={deleteLoading}
                />
            }
        </>
    )

}

export default CategoryList