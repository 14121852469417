import { BarChart } from '@mantine/charts';
import {SummaryData, CategoryColor } from '../interfaces';

export const data = [
    { month: 'January', Smartphones: 1200, Laptops: 900, Tablets: 200 },
    { month: 'February', Smartphones: 1900, Laptops: 1200, Tablets: 400 },
    { month: 'March', Smartphones: 400, Laptops: 1000, Tablets: 200 },
    { month: 'April', Smartphones: 1000, Laptops: 200, Tablets: 800 },
    { month: 'May', Smartphones: 800, Laptops: 1400, Tablets: 1200 },
    { month: 'June', Smartphones: 750, Laptops: 600, Tablets: 1000 },
  ];

  
  interface Props {
    data: SummaryData[];
    categoriesByColor: CategoryColor[];
  }
  
  function SummaryBarChart({ data, categoriesByColor }: Props) {
  return (
    <BarChart
      h={300}
      data={data}
      dataKey="month"
      type="stacked"
      withLegend
      series={categoriesByColor}
    />
  );
}

export default SummaryBarChart;