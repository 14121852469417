//
//
//  Utils
//
//

import { RequestError } from "./api.ts";

export function getAccessToken(): string | null {
    const localStorageAccessToken = localStorage.getItem("accessToken")
    const sessionStorageAccessToken = sessionStorage.getItem("accessToken")

    if (localStorageAccessToken != null) {
        return localStorageAccessToken
    } else if (sessionStorageAccessToken != null) {
        return sessionStorageAccessToken
    } else {
        return null
    }
}

export function reverse(array: Array<any>) {
    return array.map((_: any, idx: number) => array[array.length - 1 - idx])
}

export function sortByUpdatedAt(array: Array<any>) {
    const copyArray = [...array]
    copyArray.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())
    return copyArray
}

export function sortByCreatedAt(array: Array<any>) {
    const copyArray = [...array]
    copyArray.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
    return copyArray
}


export function onResourceAdd(newResource: any, listResources: any[]) {
    const index = listResources.findIndex(resource => resource.id === newResource.id)
    if (index < 0) {
        listResources.push(newResource)
        return [...listResources]
    } else {
        return listResources
    }
}

export function onResourceUpdate(updatedResource: any, listResources: any[]) {
    return listResources.map(res => {
        return res.id === updatedResource.id ? updatedResource : res
    })
}

export function onResourceDelete(deletedResource: any, listResources: any[]) {
    return listResources.filter(res => res.id != deletedResource.id)
}

export function raiseForStatus(response: Response) {
    if (!response.ok) {
        throw new RequestError(response)
    }
    return response
}


export function toJsonOrRaise(response: Response) {
    if (!response.ok) {
        throw new RequestError(response)
    }

    return response.json()
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = true, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1
    const r = 10 ** dp

    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)


    return bytes.toFixed(dp) + ' ' + units[u]
}


export function megabytes(mbs: number) {
    return mbs * (1024 ** 2)
}

export function formatDate(date: Date): string {
    // Get the individual components (day, month, year, hours, minutes)
    const day = date.getDate()
    const month = date.getMonth() + 1 // Months are zero-based, so add 1
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    // Add leading zeros to ensure two-digit formatting
    const formattedDay = day < 10 ? '0' + day : day
    const formattedMonth = month < 10 ? '0' + month : month
    const formattedHours = hours < 10 ? '0' + hours : hours
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes

    // Create the formatted string
    return `${formattedDay}/${formattedMonth}/${year} @ ${formattedHours}:${formattedMinutes}`
}

export function randomUUID() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2)
}


export function capitalizeFirstLetter(str: string | undefined): string {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function generateColorFromString(str: string) {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += value.toString(16).padStart(2, '0')
    }
    return color
  }

  export function getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  

  