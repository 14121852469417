import React from 'react'
import ReactDOM from 'react-dom/client'
import '@mantine/core/styles.css'
import '@mantine/core/styles.layer.css'
import 'mantine-datatable/styles.layer.css'
import '@mantine/dropzone/styles.css'
import '@mantine/dates/styles.css'
import '@mantine/charts/styles.css'
import App from './App.tsx'
import './index.css'
import * as Sentry from "@sentry/react"
import {BrowserRouter} from "react-router-dom"
import {MantineProvider} from "@mantine/core"
import {ModalsProvider} from "@mantine/modals"
import { generateColors } from '@mantine/colors-generator';



Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
})


ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <MantineProvider
            defaultColorScheme="light"
			theme={{
                primaryColor: "dark",
				colors: {
					'orange': generateColors('#F87212'),
					'pink': generateColors('#eb5cab'),
					'dark-purple': generateColors('#361134'),

				},
				defaultGradient: {
					from: 'orange',
					to: 'pink',
					deg: 90,
				}
            }}
        >
            <ModalsProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </ModalsProvider>
        </MantineProvider>
    </React.StrictMode>,
)
