import './App.css'
import {Route, Routes, useNavigate} from "react-router-dom"
import RequireLoggedIn from './components/RequireLoggedIn.tsx'
import {Auth0Provider} from "@auth0/auth0-react"
import Home from './pages/Home/Index.tsx'
import SettingsEdit from './pages/Settings/Edit.tsx'
import Dashboard from './pages/Home/Dashboard/index.tsx'
import CategoryList from './pages/Categories/List.tsx'
import DashboardOverview from './pages/Home/Dashboard/Overview/index.tsx'
import SummaryList from './pages/Summaries/List.tsx'
import SummaryDetail from './pages/Summaries/Detail.tsx'
import ResponseList from './pages/Responses/List.tsx'
import ResponseDetail from './pages/Responses/Detail.tsx'



function App() {
    const navigate = useNavigate()
    const onRedirectCallback = (appState: any) => {
        navigate((appState && appState.returnTo) || window.location.pathname)
    }
    
    return (
        <Auth0Provider
             domain={import.meta.env.VITE_AUTH0_DOMAIN}
             clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
             authorizationParams={{
                 redirect_uri: window.location.origin,
                 audience: import.meta.env.VITE_AUTH0_AUDIENCE
             }}
             onRedirectCallback={onRedirectCallback}
             cacheLocation="localstorage"
        >
            <Routes>
                <Route element={<RequireLoggedIn/>}>
                    <Route index Component={Home}/>
                    <Route path="/" Component={Dashboard}>
                        <Route path="settings" Component={SettingsEdit}/>
                        <Route path="dashboard" Component={DashboardOverview}/>
                        <Route path="categories" Component={CategoryList}/>
                        <Route path="summaries" Component={SummaryList}/>
                        <Route path="summaries/:summaryId" Component={SummaryDetail}/>
                        <Route path="responses" Component={ResponseList}/>
                        <Route path="responses/:responseId" Component={ResponseDetail}/>
                    </Route>
                </Route>
            </Routes>
        </Auth0Provider>
    )
}

export default App