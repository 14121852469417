import React from 'react';

interface FormattedTextProps {
    children: React.ReactNode;
}

function FormattedText({ children }: FormattedTextProps): JSX.Element {
    const text = typeof children === 'string' ? children : '';
    const lines = text.split('\n');

    return (
        <>
            {lines.map((line, index) => (
                <span key={index}>
                    {line}
                    {index !== lines.length - 1 && <br />}
                </span>
            ))}
        </>
    );
}

export default FormattedText;
