
import {Anchor, Badge, Flex, Text, Title, useMantineTheme} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import {IconCategory, IconEdit, IconPointFilled} from "@tabler/icons-react";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";

import {Summary} from "../../interfaces.ts";
import classes from "../../components/DataTable.module.css"
import {DataTableEmpty} from "../../components/DataTable.tsx";
import { Link } from "react-router-dom";
import TimeAgoLocale from "../../components/TimeAgoLocale.tsx";
import { generateColorFromString } from "../../utils.ts";


function SummariesDataTable({summaries, loading, onCreate}: {summaries: Summary[], loading: boolean, onCreate: () => void}) {
    const { t } = useTranslation();
    const theme = useMantineTheme()
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

    const colorByStatus: Record<string, string> = {
        "PENDING": "gray",
        "TRANSCRIBING": "indigo",
        "SUMMARIZING": "grape",
        "DONE": "teal",
        "ERROR": "red"
    }

    const humanReadableSummaryType: Record<string, string> = {
        "TEXT": t("Text"),
        "AUDIO": t("Audio")
    }

    const SummaryColor: Record<string, string> = {
        "TEXT": theme.colors.orange[6],
        "AUDIO": theme.colors.pink[6]
    }

    function renderSummaryDetails(summary: Summary) {

        const isNewSummary = (summary as any)._isNew == true
        const itemStyle = isNewSummary ? { backgroundColor: "#F5FBEF", marginBottom:'1rem'} : {marginBottom:'1rem'};
        let lastUpdated: string | React.ReactNode = <Text span fs="italic">{t("never")}</Text>
        
        if (summary.updated_at != null) {
            lastUpdated = <TimeAgoLocale date={new Date(summary.updated_at)} language={'en'}/>
        }
        return (
            <div className={classes.row} key={summary.id} style={itemStyle}>
                <div className={classes.rowContent}>
                    <Anchor component={Link} to={`/summaries/${summary.id}`}>
                        <Title size="h2" className={classes.rowTitle}>
                            {summary.name}
                        </Title>
                    </Anchor>
                    <Badge color={colorByStatus[summary.status]}>
                        <span style={{textTransform: "capitalize"}}>{t(summary.status)}</span>
                    </Badge>
                    <Flex gap={10} align="center" mt={4}>
                        <IconPointFilled size={24} style={{ marginRight: '-10px', color: SummaryColor[summary.type] }} />
                        <Text c="dimmed" size="sm" mt={0}>{humanReadableSummaryType[summary.type]}</Text>
                        <IconPointFilled size={6} />
                        <IconEdit size={12} color={"gray"}/>
                        <Text c="dimmed" size="sm" mt={0}>Last update: {lastUpdated}</Text>
                        <IconPointFilled size={6} />
                        <IconCategory size={12} color={"gray"}/>
                        <Text c="dimmed" size="sm" mt={0}>{t('Category:')}</Text>
                        {summary.category ? (  
                            <Badge color={generateColorFromString(summary.category.name)}>
                                <span style={{textTransform: "capitalize"}}>{t(summary.category.name)}</span>
                            </Badge>
                            ) : (
                                <Text c="dimmed" size="sm" mt={0}>{'None'}</Text>
                            )
                        }
                    </Flex>
                    
                </div>
            </div>
        );
    }

    return (
        <DataTable
            noHeader
            withRowBorders={false}
            borderColor={'white'}
            rowClassName={classes.rowTitle}
            fetching={loading}
            verticalSpacing=""
            bodyRef={bodyRef}
            style={{width:'100%'}}
            columns={[{ accessor: 'summaries', render: renderSummaryDetails }]}
            records={summaries}
            emptyState={
                summaries.length === 0 && (
                <div style={{ pointerEvents: 'all', width:'100%'}}>
                <DataTableEmpty
                    title={t("You don't have any summary")}
                    subtitle={t("Let's get started") + " 🚀"}
                    action={t("Create your first summary")}
                    onAction={onCreate}
                />
                </div>)
            }
            minHeight={300}
            defaultColumnProps={{
                noWrap: true,
                ellipsis: true,
            }}
        />
    )
}

export default SummariesDataTable



