import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import { Button, LoadingOverlay, Modal, Select, ComboboxItem, Group } from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";


import { useApiErrorHandler } from "../../hooks.ts";
import Api from "../../api.ts";
import { Summary } from "../../interfaces.ts";


interface ResponseForm {
    summary_id: number
}

function ResponseCreateModal({opened, setOpened}: {
    opened: boolean,
    setOpened: (open: boolean) => void
}) {
    const { t } = useTranslation()
    const { getAccessTokenSilently } = useAuth0()
    const handleError = useApiErrorHandler()

    const [summaries, setSummaries] = useState<ComboboxItem[]>([])
    const [createLoading, setCreateLoading] = useState(false)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }


    async function loadSummariesPage() {
        setCreateLoading(true)
        Api.getSummariesPage(await getAccessToken(), 1, 100, "DONE")
            .then(summaries => {
                const aux = summaries.items.map((summary: Summary) => {
                    return {"label": summary.name, "value": summary.id.toString()} as ComboboxItem
                })
                if (aux !== undefined) {
                    setSummaries(aux)
                }
                setCreateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateLoading(false)
            })
    }

    
    useEffect(() => {
        loadSummariesPage()
    }, [])

    const responseForm = useForm<ResponseForm>()


    function handleClose() {
        setOpened(false)
        responseForm.reset()
    }


    async function onSubmitResponse(values: ResponseForm) {
        setCreateLoading(true)
        Api.createResponse(await getAccessToken(), values.summary_id)
            .then(() => {
                setCreateLoading(false)
                responseForm.reset()
                handleClose()
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateLoading(false)
            })
    }
    
    return (
        <Modal opened={opened} onClose={() => handleClose()} title={t("Create response")} >
            <LoadingOverlay visible={createLoading}/>

            <form onSubmit={responseForm.onSubmit(onSubmitResponse)}>
                <Select
                    mt="xs"
                    label={t("Summary")}
                    placeholder={t("Select a summary")}
                    data={summaries}
                    allowDeselect={true}
                    {...responseForm.getInputProps("summary_id")}
                />
                
                <Group mt="md">
                    <Button type="submit">Create</Button>
                </Group>
            </form>
        </Modal>
    )
}

export default ResponseCreateModal