//
//
//  Profile Menu
//
//

import {Avatar, Flex, Menu} from "@mantine/core";
import classes from "./ProfileMenu.module.css";
import {IconBrandCodesandbox, IconChevronRight, IconLogout, IconSettings} from "@tabler/icons-react";
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";

function ProfileMenu({includeDashboard}: {includeDashboard?: boolean}) {
    const {user, logout} = useAuth0()

    function onLogout() {
        logout()
            .catch(console.error)
    }

    return (
        <>
            <Menu shadow="md" width={230}>
                <Menu.Target>
                    <button className={classes.navbarBtn}>
                        <Flex align="center" justify="space-between" style={{width: "100%"}}>
                            <Flex gap={10} align="center">
                                <Avatar variant="white" size="md" radius="xl" src={user?.picture}/>
                                <span>{user?.name}</span>
                            </Flex>
                            <IconChevronRight size="1rem" />
                        </Flex>
                    </button>
                </Menu.Target>
                <Menu.Dropdown>
                    {includeDashboard && <>
                            <Menu.Divider />
                            <Menu.Item leftSection={<IconBrandCodesandbox size={14} />} component={Link} to="/dashboard">
                                Dashboard
                            </Menu.Item>
                            <Menu.Divider />
                        </>
                    }
                    
                    <Menu.Item leftSection={<IconSettings size={14} />} component={Link} to="/settings">
                        Settings
                    </Menu.Item>
                    <Menu.Item leftSection={<IconLogout size={14} />} onClick={onLogout}>
                        Logout
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </>
    )
}

export default ProfileMenu