//
//
//  Media Upload
//
//

import {Dropzone, FileWithPath, MIME_TYPES} from "@mantine/dropzone";
import {Button, Flex, Image, Text} from "@mantine/core";
import {notifications} from "@mantine/notifications";
import {IconFile, IconX} from "@tabler/icons-react";
import {rem} from "@mantine/core";
import {humanFileSize} from "../utils.ts"
import { useTranslation } from "react-i18next";

export const IMAGE_MIME_TYPES: string[] = [MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.svg]
export const VIDEO_MIME_TYPES: string[] = [MIME_TYPES.mp4]
export const AUDIO_TYPES: string[] = ["audio/*"]
export const FILE_TYPES: string[] = ["message/rfc822"]


function MediaUpload({image, video, audio, file, onDrop, loading, maxSize, onDelete, acceptImages=true, acceptVideos=true, acceptAudios=true, acceptFiles=true}: {
    image?: string,
    video?: string,
    audio?: File | null,
    file?: File | null,
    onDrop: (file: FileWithPath) => void,
    loading: boolean,
    maxSize?: number,
    onDelete: () => void,
    acceptImages: boolean,
    acceptVideos: boolean,
    acceptAudios: boolean,
    acceptFiles: boolean
}) {
    
    const { t } = useTranslation()

    const accept = []
    if (acceptImages) {
        accept.push(...IMAGE_MIME_TYPES)
    }

    if (acceptVideos) {
        accept.push(...VIDEO_MIME_TYPES)
    }

    if (acceptAudios) {
        accept.push(...AUDIO_TYPES)
    }
    if (acceptFiles) {
        accept.push(...FILE_TYPES)
    }

    function onDropInner(files: File[]) {
        onDrop(files[0])
    }

    function onDeleteInner(event: any) {
        event.stopPropagation()
        onDelete()
    }

    function onRejectInner() {
        const extensions = []
        if (acceptImages) {
            extensions.push(...[".png", ".jpg", ".svg"])
        }
        if (acceptVideos) {
            extensions.push(...[".mpg"])
        }

        let text = t(`Only`) + ` ${extensions.join(", ")} ` + t(`files`)

        if (maxSize != null) {
            const humanMaxSize = humanFileSize(maxSize)
            text += ` ` + t(`less than`) + ` ${humanMaxSize}`
        }

        text += " " + t("are supported")

        notifications.show({
            icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
            color: "red",
            title: t('File not supported'),
            message: text,
        })
    }

    function content() {
        if (image != null) {
            return (
                <Flex direction="column" align="center" justify="center" gap={8}>
                    <Image
                        h="auto"
                        w="100%"
                        fit="contain"
                        src={image}
                    />
                    <Button loading={loading} variant="outline" color="red" onClick={onDeleteInner} style={{ pointerEvents: 'all' }}>{t("Clear")}</Button>
                </Flex>
            )
        } else if (video != null) {
            return (
                <Flex direction="column" align="center" justify="center" gap={8}>
                    <video controls src={video} style={{width: "100%", pointerEvents: 'all'}} onClick={ev => ev.stopPropagation()}></video>
                    <Button loading={loading} variant="outline" color="red" onClick={onDeleteInner} style={{ pointerEvents: 'all' }}>{t("Clear")}</Button>
                </Flex>
            )
        } else if (audio != null) {
            var blob = window.URL || window.webkitURL
            var fileURL = blob.createObjectURL(audio)

            return (
                <Flex direction="column" align="center" justify="center" gap={8}>
                    <audio controls src={fileURL} style={{width: "100%", pointerEvents: 'all'}} onClick={ev => ev.stopPropagation()}></audio>
                    <Button loading={loading} variant="outline" color="red" onClick={onDeleteInner} style={{ pointerEvents: 'all' }}>{t("Clear")}</Button>
                </Flex>
            )
        } else if (file != null) {
    
                return (
                    <Flex direction="column" align="center" justify="center" gap={8}>
                            <Flex justify='space-between' align='center' gap={8}>
                                <IconFile/>
                                <Text c="dimmed">
                                    {file.name}
                                </Text>
                            </Flex>
                            <Button loading={loading} variant="outline" color="red" onClick={onDeleteInner} style={{ pointerEvents: 'all' }}>{t("Clear")}</Button>
                    </Flex>
                )
        } else {
            return (
                <Flex direction="column" align="center" justify="center" gap={8}>
                    <Text fw="bold" c="dimmed" size="lg" style={{textAlign: "center"}}>
                        {t("Drag files to upload")}
                        <br/>
                        {t("or")}
                    </Text>
                    <Button radius="xl">{t("Browse")}</Button>
                </Flex>
            )
        }
    }

    return (
        <Dropzone
            loading={loading}
            multiple={false}
            maxFiles={1}
            onDrop={onDropInner}
            onReject={onRejectInner}
            maxSize={maxSize}
            accept={accept}
        >
            {content()}
        </Dropzone>
    )
}

export default MediaUpload