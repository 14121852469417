import { DonutChart } from "@mantine/charts";
import { SummariesByCategory } from "../interfaces";


function CategoriesWeightChart({data}: {data: SummariesByCategory[]}) {

  const totalElements = data.reduce((acc: number, el: SummariesByCategory) => acc + el.value, 0);

  return (
  <>
    <DonutChart
      h={190}
      data={data}
      thickness={16}
      paddingAngle={2}
      chartLabel={totalElements}
    />
  </>
  )
}

export default CategoriesWeightChart;
