import {useLocation, useNavigate} from "react-router-dom";
import React from "react";
import { notifications } from "@mantine/notifications";
import { useTranslation } from "react-i18next";


export function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
}


export function useApiErrorHandler() {
    const { t } = useTranslation()
  const navigate = useNavigate();
  let isRedirecting = false;

  return (error: any) => {
      if (error.response && error.response.status === 401) {
          if (isRedirecting) { //to avoid two errors redirecting simultaneously
              return
          }
          isRedirecting = true
          sessionStorage.removeItem("accessToken")
          localStorage.removeItem("accessToken")
          navigate('/login', {state: {from: window.location.pathname}})
      } else {
            const message = t('Oops... something went wrong. Try again later')
        
            notifications.show(
                {
                    title: 'Error',
                    message,
                    color: 'red'
                }
            )
      }
  }
}
