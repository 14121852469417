
import {Anchor, Badge, Flex, Text, Title} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import {IconEdit} from "@tabler/icons-react";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";

import {Response} from "../../interfaces.ts";
import classes from "../../components/DataTable.module.css"
import {DataTableEmpty} from "../../components/DataTable.tsx";
import { Link } from "react-router-dom";
import TimeAgoLocale from "../../components/TimeAgoLocale.tsx";


function ResponsesDataTable({responses, loading, onCreate}: {responses: Response[], loading: boolean, onCreate: () => void}) {
    const { t } = useTranslation();
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();

    const colorByStatus: Record<string, string> = {
        "PENDING": "gray",
        "GENERATING": "grape",
        "DONE": "teal",
        "ERROR": "red"
    }

    function renderResponseDetails(response: Response) {

        const isNewResponse = (response as any)._isNew == true
        const itemStyle = isNewResponse ? { backgroundColor: "#F5FBEF", marginBottom:'1rem'} : {marginBottom:'1rem'};
        let lastUpdated: string | React.ReactNode = <Text span fs="italic">{t("never")}</Text>
        
        if (response.updated_at != null) {
            lastUpdated = <TimeAgoLocale date={new Date(response.updated_at)} language={'en'}/>
        }
        return (
            <div className={classes.row} key={response.id} style={itemStyle}>
                <div className={classes.rowContent}>
                    <Anchor component={Link} to={`/responses/${response.id}`}>
                        <Title size="h2" className={classes.rowTitle}>
                            {response.summary.name}
                        </Title>
                        <Badge color={colorByStatus[response.status]}>
                            <span style={{textTransform: "capitalize"}}>{t(response.status)}</span>
                        </Badge>
                    </Anchor>

                    <Flex gap={10} align="center" mt={4}>
                        <IconEdit size={12} color={"gray"}/>
                        <Text c="dimmed" size="sm" mt={0}>Last update: {lastUpdated}</Text>
                    </Flex>
                </div>
            </div>
        );
    }

    return (
        <DataTable
            noHeader
            withRowBorders={false}
            borderColor={'white'}
            rowClassName={classes.rowTitle}
            fetching={loading}
            verticalSpacing=""
            bodyRef={bodyRef}
            style={{width:'100%'}}
            columns={[{ accessor: 'responses', render: renderResponseDetails }]}
            records={responses}
            emptyState={
                responses.length === 0 && (
                <div style={{ pointerEvents: 'all', width:'100%'}}>
                <DataTableEmpty
                    title={t("You don't have any response")}
                    subtitle={t("Let's get started") + " 🚀"}
                    action={t("Create your first response")}
                    onAction={onCreate}
                />
                </div>)
            }
            minHeight={300}
            defaultColumnProps={{
                noWrap: true,
                ellipsis: true,
            }}
        />
    )
}

export default ResponsesDataTable



