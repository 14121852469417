
import {Anchor, Flex, Text, Title} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import {IconEdit} from "@tabler/icons-react";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useTranslation } from "react-i18next";

import {Category} from "../../interfaces.ts";
import classes from "../../components/DataTable.module.css"
import {DataTableEmpty} from "../../components/DataTable.tsx";
import TimeAgoLocale from "../../components/TimeAgoLocale.tsx";
//import TimeAgoLocale from "../../components/TimeAgoLocale.tsx";


function CategoriesDataTable({categories, loading, onCreate, onSelection}: {categories: Category[], loading: boolean, onCreate: () => void, onSelection: (category: Category) => void}) {
    const { t/*, i18n*/ } = useTranslation();
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>();
    function renderCategoryDetails(category: Category) {

        const isNewCategory = (category as any)._isNew == true
        const itemStyle = isNewCategory ? { backgroundColor: "#F5FBEF", marginBottom:'1rem'} : {marginBottom:'1rem'};
        let lastUpdated: string | React.ReactNode = <Text span fs="italic">{t("never")}</Text>

        if (category.updated_at != null) {
            lastUpdated = <TimeAgoLocale date={new Date(category.updated_at)} language={'en'}/>
        }
        return (
            <div className={classes.row} key={category.id} style={itemStyle}>
                <div className={classes.rowContent}>
                    <Anchor onClick={() => onSelection(category)}>
                        <Title size="h3" className={classes.rowTitle}>
                            {category.name}
                        </Title>
                    </Anchor>
                    <Flex className={classes.rowFooter}>
                        <Flex align="center" columnGap={6}>
                            <IconEdit size={12} color={"gray"}/>
                            <Text c="dimmed" size="sm" mt={0}>Last update: {lastUpdated}</Text>
                        </Flex>
                    </Flex>
                </div>
            </div>
        );
    }

    return (
        <DataTable
            noHeader
            withRowBorders={false}
            borderColor={'white'}
            rowClassName={classes.rowTitle}
            fetching={loading}
            verticalSpacing=""
            bodyRef={bodyRef}
            style={{width:'100%'}}
            columns={[{ accessor: 'categories', render: renderCategoryDetails }]}
            records={categories}
            emptyState={
                categories.length === 0 && (
                <div style={{ pointerEvents: 'all', width:'100%'}}>
                <DataTableEmpty
                    title={t("You don't have any category")}
                    subtitle={t("Let's get started") + " 🚀"}
                    action={t("Create your first category")}
                    onAction={onCreate}
                />
                </div>)
            }
            minHeight={300}
            defaultColumnProps={{
                noWrap: true,
                ellipsis: true,
            }}
        />
    )
}

export default CategoriesDataTable



