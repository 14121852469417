import TimeAgo from 'react-timeago';


// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

// @ts-ignore
import strings_en from 'react-timeago/lib/language-strings/en';

// @ts-ignore
import strings_fr from 'react-timeago/lib/language-strings/fr';

// @ts-ignore
import strings_es from 'react-timeago/lib/language-strings/es';

// @ts-ignore
import strings_de from 'react-timeago/lib/language-strings/de';

// @ts-ignore
import strings_it from 'react-timeago/lib/language-strings/it';

type options = {
    [key: string]: any
}

const stringsLanguages: options = {
   'en': strings_en,
   'fr': strings_fr,
   'es': strings_es,
   'de': strings_de,
   'it': strings_it
}

interface TimeAgoLocaleProps {
    date: Date
    language: string
}

export default function TimeAgoLocale (props: TimeAgoLocaleProps) {
    const {date, language} = props
    const formatter = buildFormatter(stringsLanguages[language]);
    return <TimeAgo date={date} formatter={formatter}/>;
}